import React from "react";
import "./pages.css";
import Navbar from "../components/Navbar/Navbar";
import exhibitionsArchive from "./exhibitionsArchive";
import { Link } from "react-router-dom";
import "./pages.css";

const Exhibitions = () => {
  return window.innerWidth < 768 ? (
    // true for mobile device
    <div>
      <div className="item">
        <Navbar page="Exhibitions" />
      </div>
      <div>
        <div className="item justify-content-right">
          <div>
            {exhibitionsArchive.map((item, index) => {
              return (
                <div>
                  <div className="item">
                    <Link to={item.route} className="a-link float-right">
                      <img src={item.frontImage} width="100%"></img>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={item.route} className="a-link">
                      <a className="a-title">{item.title}</a>
                      <br />
                      <a className="a-text">{item.location}</a>
                      <br />
                      <a className="a-text">{item.year}</a>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    // false for not mobile device

    <div className="container3070">
      <div className="item">
        <Navbar page="Exhibitions" />
      </div>
      <div className="container3070">
        <div></div>
        <div className="item justify-content-right">
          <div>
            {exhibitionsArchive.map((item, index) => {
              return (
                <div className="container5050">
                  <div className="item">
                    <Link to={item.route} className="a-link">
                      <a className="a-title">{item.title}</a>
                      <br />
                      <a className="a-text">{item.location}</a>
                      <br />
                      <a className="a-text">{item.year}</a>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={item.route} className="a-link float-right">
                      <img src={item.frontImage} width="100%"></img>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Exhibitions;
