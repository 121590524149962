import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Projects from "../../pages/Projects";
import { propTypes } from "react-bootstrap/esm/Image";

const navbar = (props) => {
  return (
    <div className="sidenav">
      <div>
        {(props.page == "Projects") |
        (props.page == "Research") |
        (props.page == "Exhibitions") ? (
          <Link to="/About" className="a-dark">
            Louise Deprez
          </Link>
        ) : (
          <Link to="/" className="a-dark">
            Louise Deprez
          </Link>
        )}
        {(props.page == "Projects") |
        (props.page == "Research") |
        (props.page == "Exhibitions") ? (
          <Link to="/About" className="box-shadow-menu"></Link>
        ) : (
          <Link to="/" className="box-shadow-menu"></Link>
        )}

        <br />
        <br />
      </div>
      <div>
        <Link
          to="/Projects"
          className={
            (props.page == "Projects") & (props.subtype == "")
              ? "a-dark"
              : "a-light"
          }
        >
          Projects
        </Link>
      </div>
      {(props.page == "Projects") |
      (props.subtype == "architecture") |
      (props.subtype == "object") ? (
        <div>
          <Link
            to="/Architecture"
            className={
              props.subtype == "architecture" ? "a-dark-s" : "a-light-s"
            }
          >
            Architecture
          </Link>
        </div>
      ) : (
        <div></div>
      )}
      {(props.page == "Projects") |
      (props.subtype == "architecture") |
      (props.subtype == "object") ? (
        <div>
          <Link
            to="/Objects"
            className={props.subtype == "object" ? "a-dark-s" : "a-light-s"}
          >
            Objects
          </Link>
        </div>
      ) : (
        <div></div>
      )}

      <div>
        <Link
          to="/Research"
          className={props.page == "Research" ? "a-dark" : "a-light"}
        >
          Research
        </Link>
      </div>
      <div>
        <Link
          to="/Exhibitions"
          className={props.page == "Exhibitions" ? "a-dark" : "a-light"}
        >
          Exhibitions
        </Link>
      </div>
    </div>
  );
};
export default navbar;
