// Thesis
import frontThesis from "./images/2020_Thesis/Artboard 1_1.png";
import Thesis1 from "./images/2020_Thesis/Artboard 7_1_2.png";
import Thesis2 from "./images/2020_Thesis/LouiseDeprez_poster_ienetprijzen.png";
import frontDCC from "./images/2022_DCC/Picture2.png";
import DCC1 from "./images/2022_DCC/8GDmethods.PNG";
import DCC2 from "./images/2022_DCC/GAN2.png";
import DCC3 from "./images/2022_DCC/P2P2.png";
import DCC4 from "./images/2022_DCC/ARM2.png";
import DCC5 from "./images/2022_DCC/Capture_ProceedingsDCC22.PNG";
import frontIJAC from "./images/2022_IJAC/Picture1.png";
import IJAC1 from "./images/2022_IJAC/Picture3.png";
import IJAC2 from "./images/2022_IJAC/Tekengebied 50@4x.png";

const researchArchive = [
  {
    type: "research",
    title:
      "Reconversion of a building to a new functional program using graph matching",
    titleEng:
      "Reconversion of a building to a new functional program using graph matching",
    subtitle: "Publication in International Journal of Architectural Computing",
    subtitleEng: "Publication in International Journal of Architectural Computing",
    route: "/Research/IJAC",
    location: "IJAC online publicatie",
    locationEng: "IJAC online publication",
    year: "2023",
    frontImage: frontIJAC,
    images: [IJAC1, IJAC2],
    omschrijving: [
      <a className="a-textBig">
        Bouwen, afbreken en heropbouwen resulteert in een grote afvalberg die
        verkleind kan worden door leegstaande gebouwen te herbestemmen. Om na te
        gaan of een gebouw een bepaald nieuw programma kan onderbrengen, is veel
        tijd en onderzoek nodig. In dit verslag hebben we een methode ontwikkeld
        die het proces van een gebouw programmatorisch in te vullen kan
        automatiseren, door gebruik te maken van methoden gebaseerd op de grafen
        theorie (=“graph theory”). Hierbij worden zowel het leegstaande gebouw
        als het nieuwe, functionele programma weergegeven als grafen met
        attributen. Aan de hand van deze representatie kunnen de knopen van de
        twee grafen aan elkaar gekoppeld worden, wat resulteert in het invullen
        van de leegstaande ruimtes met een nieuwe functie.
      </a>,
    ],
    omschrijvingEng: [
      <a className="a-textBigEng">
        Building, demolishing and rebuilding new structures, instead of
        renovating vacant buildings for adaptive reuse, generates a significant
        amount of waste. Quickly assessing if and how a building can house a new
        function can be time-consuming. We developed a method that automates
        this process using a graph-based strategy in which both the building and
        the functional program are represented by attributed graphs.
        Specifically, the new method performs a node matching strategy based on
        the properties of the nodes and the topological structure of the graphs
        by which each empty room is filled with an activity of the new function.
      </a>,
    ],
    opdrachtinfo: [
      "Team",
      "Louise Deprez, Ruben Vertraeten, Pieter Pauwels (UGent)",
      "",
      "Grasshopper plug-in",
      <a
        href="https://www.food4rhino.com/en/app/plan-graph"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Plan Graph
      </a>,
      "",
      "DOI",
      <a
        href="https://doi.org/10.1177/14780771231197790"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        10.1177/14780771231197790
      </a>,
    ],
    opdrachtinfoEng: [
      "Team",
      "Louise Deprez, Ruben Vertraeten, Pieter Pauwels (UGent)",
      "",
      "Grasshopper plug-in",
      <a
        href="https://www.food4rhino.com/en/app/plan-graph"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Plan Graph
      </a>,
      "",
      "DOI",
      <a
        href="https://doi.org/10.1177/14780771231197790"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        10.1177/14780771231197790
      </a>,
    ],
  },
  {
    type: "research",
    title:
      "Data-based generation of residential floorplans using neural networks",
    titleEng:
      "Data-based generation of residential floorplans using neural networks",
    subtitle:
      "Tenth International Conference on Design Computing and Cognition",
    subtitleEng:
      "Tenth International Conference on Design Computing and Cognition",
    route: "/Research/DCC",
    location: "VK, Glasgow",
    locationEng: "UK, Glasgow",
    year: "2022",
    frontImage: frontDCC,
    images: [DCC5, DCC2, DCC3, DCC4],
    omschrijving: [
      <a className="a-textBig">
        De meeste generatief ontwerp applicaties, die gebruikt kunnen worden in
        het architecturale ontwerpproces, zijn ontwikkeld op basis van
        regelgebaseerde strategieën. Hierbij zijn de regels veelal opgesteld
        door experts in het architecturale domein. In andere industrieën is het
        bewezen dat applicaties kunnen verbeterd worden door de regels, die vast
        geprogrammeerd zitten in de software, te vervangen door neurale
        netwerken, een artificiële intelligentie strategie, of door de regels te
        gebruiken in combinatie met neurale netwerken. Het automatisch genereren
        van een grondplan op basis van strikte functionele eisen en
        nabijheidseisen van ruimtes is bovendien nog een open onderzoeksvraag.
        Generatieve ontwerp strategieën stuiten op hun grenzen wanneer deze
        gebruikt worden in dit nabijheidsvraagstuk, daarom worden er in deze
        paper nieuwe strategieën getest: er worden neurale netwerken ingezet en
        getest om residentiële grondplannen te genereren. Hiermee wordt er
        beoogd de voor- en nadelen van verschillende neurale netwerk strategieën
        bloot te leggen. Concreet worden bestaande neurale netwerken vanuit
        verschillende industrieën toegepast in deze nieuwe context op een
        dataset bestaande uit residentiële grondplannen.
      </a>,
    ],
    omschrijvingEng: [
      <a className="a-textBigEng">
        Most generative design applications used in architectural design are
        developed with rule-based approaches, based on rules collected from
        expert knowledge and experience. In other domains, machine learning and,
        more in particular, neural networks have proven their usefulness and
        added value in replacing these hard-coded rules or improving
        applications when combining these two strategies. Since the space
        allocation problem still remains an open research question and common
        generative design techniques showed their limitations trying to solve
        this problem, new techniques need to be explored. In this paper, the
        application of neural networks to solve the space allocation problem for
        residential floor plans is tested. This research aims to expose the
        advantages as well as the difficulties of using neural networks by
        reviewing existing neural network architectures from different domains
        and by applying and testing them in this new context using a dataset of
        residential floor plans.
      </a>,
    ],
    opdrachtinfo: [
      "Auteurs",
      "Louise Deprez, Ruben Vertraeten, Pieter Pauwels (UGent)",
      "",
      "Conferentie",
      "Design Computing and Cognition'22",
      "",
      "DOI",
      <a
        href="https://doi.org/10.1007/978-3-031-20418-0_20"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        10.1007/978-3-031-20418-0_20
      </a>,
    ],
    opdrachtinfoEng: [
      "Authors",
      "Louise Deprez, Ruben Vertraeten, Pieter Pauwels (UGent)",
      "",
      "Conference and proceedings",
      "Design Computing and Cognition'22",
      "",
      "DOI",
      <a
        href="https://doi.org/10.1007/978-3-031-20418-0_20"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        10.1007/978-3-031-20418-0_20
      </a>,
    ],
  },
  {
    type: "research",
    title:
      "Automatisch genereren van lasercutpatronen uit een 3D BIM/CAD model",
    titleEng:
      "Automatic generation of laser cutting patterns from 3D BIM/CAD models",
    subtitle: "Masterproef",
    subtitleEng: "Master's thesis",
    route: "/Research/Thesis",
    location: "Universiteit Gent",
    locationEng: "Ghent University",
    year: "2019 - 2020",
    frontImage: frontThesis,
    images: [Thesis1, Thesis2],
    omschrijving: [
      <a className="a-textBig">
        Deze masterproef onderzoekt de mogelijkheid om vanuit een 3D BIM/CAD
        model automatisch lasercutpatronen te genereren. Meer bepaald wordt er
        een applicatie, Paper Wasp, ontwikkeld die de ontwerper zal helpen bij
        het produceren van een maquette. Het resultaat van deze automatisatie is
        een tijdswinst voor de ontwerper en een verlaging van het risico op
        fouten in de maquette. Deze ontwikkeling wordt gelinkt aan het
        BIM-proces, zodat vanuit één gecentraliseerd model niet alleen plannen
        en sneden kunnen gegenereerd worden, maar ook de snijpatronen om een
        schaalmodel te maken.
        <br />
        <br />
        De masterproef kan gelezen worden via deze{" "}
        <a
          href="https://lib.ugent.be/nl/catalog/rug01:002946189?i=0&q=louise+deprez"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBig"
        >
          link
        </a>
        .
      </a>,
    ],
    omschrijvingEng: [
      <a className="a-textBigEng">
        This thesis is a study of the possibility to automatically generate
        laser cutting patterns from 3D BIM/CAD models. More specifically, it is
        a development of an application, Paper Wasp, that will help designers to
        produce physical models. By automating this process, the designer can
        save time and reduce the risk of errors in the scale model. This
        development will be linked to the BIM-process, in this way not only
        plans and sections can be made from one centralised digital model, but
        also cutting patterns can be made to produce scale models.
        <br />
        <br />
        The thesis is available through this{" "}
        <a
          href="https://lib.ugent.be/nl/catalog/rug01:002946189?i=0&q=louise+deprez"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBigEng"
        >
          link
        </a>
        .
      </a>,
    ],
    opdrachtinfo: [
      "Auteurs",
      "Louise Deprez",
      "",
      "Begeleidingsteam",
      "prof. ir.-arch. Ruben Vertraeten, arch. Willem Bekers, ir.-arch. Jeroen Werbrouck (UGent)",
      "",
      "Wedstrijd",
      "Finalist ie-net-prijzen 2020",
    ],
    opdrachtinfoEng: [
      "Authors",
      "Louise Deprez",
      "",
      "Guidance",
      "prof. ir.-arch. Ruben Vertraeten, arch. Willem Bekers, ir.-arch. Jeroen Werbrouck (UGent)",
      "",
      "Competition",
      "Finalist ie-net-prijzen 2020",
    ],
  },
];

export default researchArchive;
