// Tweelinggebouw
import frontTweelinggebouw from "./images/2017_Tweelinggebouw/_MG_1255_S.jpg";
import ImTweelinggebouw1 from "./images/2017_Tweelinggebouw/_MG_1184_M.jpg";
import ImTweelinggebouw2 from "./images/2017_Tweelinggebouw/_MG_1210_M.jpg";
import ImTweelinggebouw3 from "./images/2017_Tweelinggebouw/_MG_1211_M.jpg";
import ImTweelinggebouw4 from "./images/2017_Tweelinggebouw/_MG_1245_M.jpg";
import ImTweelinggebouw5 from "./images/2017_Tweelinggebouw/_MG_1248_M.jpg";
import ImTweelinggebouw6 from "./images/2017_Tweelinggebouw/_MG_1267_M.jpg";
import ImTweelinggebouw7 from "./images/2017_Tweelinggebouw/axo_A3_crop_M.png";
import ImTweelinggebouw8 from "./images/2017_Tweelinggebouw/inplantingsplan-A1-Zwartwit_crop_M.png";
// Rectoraat
import frontRectoraat from "./images/2017_Rectoraat/_MG_1058_2_S.jpg";
import ImRectoraat1 from "./images/2017_Rectoraat/_MG_1021_M.jpg";
import ImRectoraat2 from "./images/2017_Rectoraat/_MG_1023_M.jpg";
import ImRectoraat3 from "./images/2017_Rectoraat/_MG_1024_M.jpg";
import ImRectoraat4 from "./images/2017_Rectoraat/_MG_1025_M.jpg";
import ImRectoraat5 from "./images/2017_Rectoraat/Inkedrectoraat_plannen_jury_crop_M.jpg";
import ImRectoraat6 from "./images/2017_Rectoraat/_MG_1050_edit_M.jpg";
import ImRectoraat7 from "./images/2017_Rectoraat/rectoraat_gevelwest_jury_cropped_M.png";
// Winkelpui
import frontWinkelpui from "./images/2016_Winkelpui/_MG_1090_S.jpg";
import ImWinkelpui1 from "./images/2016_Winkelpui/_MG_0693_crop_M.jpg";
import ImWinkelpui2 from "./images/2016_Winkelpui/_MG_1060_M.jpg";
import ImWinkelpui3 from "./images/2016_Winkelpui/_MG_1072_M.jpg";
import ImWinkelpui4 from "./images/2016_Winkelpui/_MG_1105_M.jpg";
// Palazzo
import frontPalazzo from "./images/2016_Palazzo/_MG_0926_S.jpg";
import ImPalazzo1 from "./images/2016_Palazzo/_MG_0938_M.jpg";
import ImPalazzo2 from "./images/2016_Palazzo/_MG_0941_M.jpg";
import ImPalazzo3 from "./images/2016_Palazzo/_MG_0963_M.jpg";
import ImPalazzo4 from "./images/2016_Palazzo/Capture_inplantingPalazzo.PNG";
// StrettoRek
import frontStrettoRek from "./images/2021_StrettoRek/_MG_9597_S.jpg";
import ImStrettoRek1 from "./images/2021_StrettoRek/_MG_9559_M.jpg";
import ImStrettoRek2 from "./images/2021_StrettoRek/_MG_9609_M.jpg";
import ImStrettoRek3 from "./images/2021_StrettoRek/_MG_9683_M.jpg";
import ImStrettoRek4 from "./images/2021_StrettoRek/Tekengebied 1@4x_copy_M.png";
// 169 Olijfstraat Marge
import frontOlijf from "./images/2023_Olijfstraat/169 - OFG - scenario 4_cropBW.png";
import ImOlijf1 from "./images/2023_Olijfstraat/169 - OFG - scenario 4_zoom.png";
import ImOlijf2 from "./images/2023_Olijfstraat/169 - OFG - scenario 5_zoom.png";
// 184 Maanstraat Marge
import frontMaan from "./images/2023_Maanstraat/184 - MSL - gevel - VAR1_crop.jpg";
import ImMaan1 from "./images/2023_Maanstraat/184 - MSL - gevel - VAR1.jpg";
import ImMaan2 from "./images/2023_Maanstraat/184 - MSL - gevel - VAR2.jpg";
import ImMaan3 from "./images/2023_Maanstraat/184 - MSL - gevel - VAR3.jpg";
import ImMaan4 from "./images/2023_Maanstraat/184 maxi fase 1_zoom.jpg";
import ImMaan5 from "./images/2023_Maanstraat/184 maxi fase 2_zoom.jpg";

const projectArchive = [
  {
    type: "project",
    subtype: "architecture",
    title: "Klein en fijn",
    titleEng: "Klein en fijn",
    subtitle: "Schetsontwerp",
    subtitleEng: "Sketch design",
    route: "/Projects/Klein&Fijn",
    location: "Gent",
    locationEng: "Ghent",
    year: "2023",
    frontImage: frontOlijf,
    images: [
      ImOlijf1,
      ImOlijf2,
    ],
    omschrijving: [
      <a className="a-textBig">
        In het fijne stadsweefsel van Gent, omsloten door bebouwing, 
        stijgt een daktuin naar boven. Hoger en hoger, uitkijkend naar de 
        zon. De kleine footprint en beperkte hoogte maken het 
        complexer, maar des te interessanter! Bovendien trekken 
        splitlevels de zon tot diep in de woning en zorgen ze voor een 
        groots ruimtelijk gevoel.
      </a>,
    ],
    omschrijvingEng: [],
    opdrachtinfo: [
      "Bureau",
      <a
        href="https://margearchitecten.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Marge Architecten
      </a>,
      "",
      "Team",
      "Louise Deprez, Valérie Filliers, Steven Schreurs",
    ],
    opdrachtinfoEng: [
      "Bureau",
      <a
        href="https://margearchitecten.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Marge Architecten
      </a>,
      "",
      "Team",
      "Louise Deprez, Valérie Filliers, Steven Schreurs",
    ],
  },{
    type: "project",
    subtype: "architecture",
    title: "Op de groei",
    titleEng: "Op de groei",
    subtitle: "Voorontwerp",
    subtitleEng: "Preliminary draft",
    route: "/Projects/OpDeGroei",
    location: "Lokeren",
    locationEng: "Lokeren",
    year: "2023",
    frontImage: frontMaan,
    images: [
      ImMaan1,
      ImMaan2,
      ImMaan3,
      ImMaan4,
      ImMaan5,
    ],
    omschrijving: [
      <a className="a-textBig">
        Een project op de groei, in twee fasen. Eerst een schuine 
        achterbouw om licht te happen, springend van buur naar buur. 
        Daarna nog een uitsprong erbovenop, om ver vooruit te kunnen 
        kijken. Voor de eerste fase worden er drie scenario’s uitgewerkt 
        die verfijnd en gecombineerd kunnen worden naar de smaak 
        van de bouwheer. Een methodiek waarbij de wens van de 
        bouwheer centraal staat en die de werkwijze bij Marge 
        architecten sterk kenmerkt.
      </a>,
    ],
    omschrijvingEng: [],
    opdrachtinfo: [
      "Bureau",
      <a
        href="https://margearchitecten.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Marge Architecten
      </a>,
      "",
      "Team",
      "Louise Deprez, Ilse De Roock, Thomas Roelandts",
    ],
    opdrachtinfoEng: [
      "Bureau",
      <a
        href="https://margearchitecten.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Marge Architecten
      </a>,
      "",
      "Team",
      "Louise Deprez, Ilse De Roock, Thomas Roelandts",
    ],
  },{
    type: "project",
    subtype: "object",
    title: "Stretto 00",
    titleEng: "Stretto 00",
    subtitle: "",
    subtitleEng: "",
    route: "/Projects/Stretto_00",
    location: "Gent",
    locationEng: "Ghent",
    year: "2021",
    frontImage: frontStrettoRek,
    images: [ImStrettoRek2, ImStrettoRek4, ImStrettoRek3, ImStrettoRek1],
    omschrijving: [
      <a className="a-textBig">
        Stretto 00 is deel van een interieurproject waarbij vergeten objecten
        een nieuwe plaats krijgen, oude objecten gerecycleerd worden en nieuwe
        objecten lokaal geproduceerd worden.
      </a>,
    ],
    omschrijvingEng: [
      <a className="a-textBigEng">
        Stretto 00 is part of an interior design project, where forgotten
        objects find a new place, old objects are recycled and new objects are
        locally produced.
      </a>,
    ],
    opdrachtinfo: [
      "Team",
      "Stijn Beke, Koen Deprez, Louise Deprez",
      "",
      "Fotografie",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
    opdrachtinfoEng: [
      "Team",
      "Stijn Beke, Koen Deprez, Louise Deprez",
      "",
      "Photography",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
  },
  {
    type: "project",
    subtype: "architecture",
    title: "Bestuurszaal",
    titleEng: "Boardroom",
    subtitle: "Derde bachelor architectuurontwerp",
    subtitleEng: "Third bachelor architectural design",
    route: "/Projects/Bestuurszaal",
    location: "Gent, Sint-Pietersnieuwstraat 25",
    locationEng: "Ghent, Sint-Pietersnieuwstraat 25",
    year: "2017",
    frontImage: frontRectoraat,
    images: [
      ImRectoraat5,
      ImRectoraat1,
      ImRectoraat4,
      ImRectoraat2,
      ImRectoraat3,
      ImRectoraat6,
      ImRectoraat7,
    ],
    omschrijving: [
      <a className="a-textBig">
        Het rectoraatsgebouw langsheen de kade van de Muinkschelde en de Sint
        Pietersnieuwstraat in Gent krijgt in dit ontwerp een nieuwe bekroning.
        De opdracht gaat niet uit van een concrete nood, maar wel van een wens
        om een nieuwe raadzaal te bouwen die meer allure en sfeer uitstraalt.
        Deze betonnen bekroning wordt een symbolische plek waar de raad van
        bestuur van de Universiteit Gent vergaderingen zal houden en belangrijke
        beslissingen zal nemen.
        <br />
        <br />
        De 41 bestuursleden worden samen letterlijk rond de tafel gezet, ieder
        krijgt een evenwaardige stem zonder één dominante plaats aan de gebogen
        tafels. Boven het centrum van discussie komen de bogen uit cortenstaal
        samen en de grote ramen zorgen voor een gerichte blik op de
        verschillende universiteitscampussen in het centrum van Gent. Op de
        voormalige bovenste verdieping van het rectoraatsgebouw wordt een foyer
        ingericht. Het wordt een plek om na te praten, koffie te drinken en
        recepties te houden, ondersteund door enkele functies zoals een
        vestiaire, een berging en een kleine keuken. Het is een overgangsgebied
        waar twee architecturale stijlen elkaar raken, waar de cortenstalen
        trapkoker al enige notie geeft van wat er boven staat te gebeuren.
      </a>,
    ],
    omschrijvingEng: [],
    opdrachtinfo: [
      "Team",
      "Louise Deprez",
      "",
      "Begeleidingsteam",
      "Trice Hofkens, Lieve Van De Ginste (UGent)",
      "",
      "Fotografie",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
    opdrachtinfoEng: [
      "Team",
      "Louise Deprez",
      "",
      "Guidance",
      "Trice Hofkens, Lieve Van De Ginste (UGent)",
      "",
      "Photography",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
  },
  {
    type: "project",
    subtype: "architecture",
    title: "Tweelinggebouw",
    subtitle: "Derde bachelor architectuurontwerp",
    subtitleEng: "Third bachelor architectural design",
    route: "/Projects/Tweelinggebouw",
    location: "Gent, Woodrow Wilsonplein",
    locationEng: "Ghent, Woodrow Wilsonplein",
    year: "2017",
    frontImage: frontTweelinggebouw,
    images: [
      ImTweelinggebouw1,
      ImTweelinggebouw8,
      ImTweelinggebouw3,
      ImTweelinggebouw2,
      ImTweelinggebouw7,
      ImTweelinggebouw4,
      ImTweelinggebouw5,
      ImTweelinggebouw6,
    ],
    omschrijving: [
      <a className="a-textBig">
        Geïnspireerd door de tijdelijke invulling van de oude Gentse
        stadsbibliotheek, het "Nest" genoemd in de volksmond, in combinatie met
        het oorspronkelijke plan van de Gentse architect Geo Bontinck om een
        "tweelinggebouw" te voorzien op de kop van het Zuid als toegang tot het
        Koning Albertpark, wordt een ontwerpvoorstel gemaakt om het
        "ontbrekende" gebouw te ontwerpen. Startende, tijdelijke of
        doorgroeiende creatievelingen met atypisch ruimtegebruik, die buiten de
        klassieke huurmarkt vallen, worden ondergebracht in dit nieuwe,
        flexibele gebouw.
        <br />
        <br />
        Er wordt een maximumvolume gecreëerd dat gedragen kan worden door de
        site en de omgeving: nl. de maximale footprint en de hoogte van de
        hoogste omliggende gebouwen. Binnenin dit volume wordt een permanent
        stalen skelet ontworpen waaraan tijdelijke, veranderlijke houten
        structuren kunnen bevestigd worden. Deze houten volumes kunnen bij een
        nieuw gebruik naar wens aangepast worden, waardoor een intern circulair
        materiaalgebruik gestimuleerd wordt. De vloeroppervlaktes, de hoogte van
        de volumes en de ligging binnenin het gebouw zijn vrij te bepalen.
        Binnenin de houten volumes bevinden zich de private ruimtes van de
        kleine ondernemingen en de resulterende ruimte bovenop en tussen de
        houten volumes zijn collectief en stimuleren onderlinge creatieve
        samenwerking. Dit flexibele interieur wordt omhuld door een betonnen
        gevel met twee betonnen kokers waarin permanente functies, zoals
        sanitair en verticale circulatie, ondergebracht worden. Door de
        flexibele volumes in hout uit te werken, wordt er enerzijds een geborgen
        sfeer gecreëerd binnenin een robuust gebouw uit staal en beton en wordt
        anderzijds het contrast tussen permanente ruwbouw en flexibele invulling
        geaccentueerd.
      </a>,
    ],
    omschrijvingEng: [],
    opdrachtinfo: [
      "Team",
      "Louise Deprez, Jens Ockerman",
      "",
      "Begeleidingsteam",
      "Bert Haerynck, Trice Hofkens (UGent)",
      "",
      "Fotografie",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
    opdrachtinfoEng: [
      "Team",
      "Louise Deprez, Jens Ockerman",
      "",
      "Guidance",
      "Bert Haerynck, Trice Hofkens (UGent)",
      "",
      "Photography",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
  },
  {
    type: "project",
    subtype: "architecture",
    title: "Winkelpui",
    subtitle: "Tweede bachelor architectuurontwerp",
    subtitleEng: "Second bachelor architectural design",
    route: "/Projects/Winkelpui",
    location: "Brugge, Zuidzandstraat 35",
    locationEng: "Bruges, Zuidzandstraat 35",
    year: "2016",
    frontImage: frontWinkelpui,
    images: [ImWinkelpui1, ImWinkelpui2, ImWinkelpui3, ImWinkelpui4],
    omschrijving: [
      <a className="a-textBig">
        In winkelstraten in historische binnensteden heerst vaak een
        half-verdoken leegstand, waardoor na sluitingsuur deze straten vaak
        leeglopen. Dit ontwerpvoorstel speelt in op deze problematiek door een
        pui te ontwerpen die zowel een winkel als een woning ontsluit. Voor een
        voormalige snoepwinkel in Brugge wordt een dubbele gevel ontworpen die
        een tussenruimte creëert met een flexibele invulling. De tijdstippen van
        de dag en de seizoenen zullen het gebruik van de pui bepalen. Een
        rustplek voor winkeliers overdag kan 's avonds een ontmoetingsruimte
        voor de bewoner worden. Waar in de winter een warme bar of sas ontstaat,
        zal in de zomer een open schaduwplek ontstaan. De grote accordeondeur
        wordt dan uitnodigend opengezet en de snoeprekken kunnen naar buiten
        gerold worden.
      </a>,
    ],
    omschrijvingEng: [],
    opdrachtinfo: [
      "Team",
      "Louise Deprez",
      "",
      "Begeleidingsteam",
      "Sven Verbruggen (UGent)",
      "",
      "Fotografie",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
    opdrachtinfoEng: [
      "Team",
      "Louise Deprez",
      "",
      "Guidance",
      "Sven Verbruggen (UGent)",
      "",
      "Photography",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
  },
  {
    type: "project",
    subtype: "architecture",
    title: "Palazzo",
    subtitle: "Tweede bachelor architectuurontwerp",
    subtitleEng: "Second bachelor architectural design",
    route: "/Projects/Palazzo",
    location: "Brugge, Zwijnstraat",
    locationEng: "Bruges, Zwijnstraat",
    year: "2016",
    frontImage: frontPalazzo,
    images: [ImPalazzo2, ImPalazzo3, ImPalazzo1, ImPalazzo4],
    omschrijving: [
      <a className="a-textBig">
        In 2015 beslist het stadsbestuur van Brugge de beurshal, gebouwd in de
        jaren '60, af te breken en op deze locatie een nieuwe infrastructuur te
        bouwen. Met het Palazzo wordt een ontwerpvoorstel ontwikkeld waarbij er
        plaats gemaakt wordt voor 3000 m² aan bewoonbare oppervlakte en een
        sokkel van 1300 m² vol kleinschalige publieke ruimten.
        <br />
        <br />
        Binnenin de onvolmaakte rooilijn wordt een regelmatig grid ontworpen dat
        enerzijds rust geeft in het interieur en anderzijds een spanning creëert
        met het stedelijk weefsel. De grootste spanning ontstaat waar de koer
        raakt aan het stedelijk domein, een plek waar twee constrasterende
        gevelpatronen raken. De publieke ruimten in de sokkel, toegankelijk
        vanaf het stedelijk domein, bieden een zicht op de koer. De 30 woningen,
        toegankelijk via verlaagde rondgangen rond de koer, krijgen door het
        niveauverschil een duidelijk uitzicht op de koer en tegelijk een
        belemmerde inkijk in de woning. De ontdubbelde buitengevel zorgt niet
        alleen voor een buitenruimte bij iedere woning, maar geeft op deze
        manier ook een publieke uitstraling aan het gebouw.
      </a>,
    ],
    omschrijvingEng: [],
    opdrachtinfo: [
      "Team",
      "Louise Deprez",
      "",
      "Begeleidingsteam",
      "Tania Vandenbussche (UGent)",
      "",
      "Fotografie",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
    opdrachtinfoEng: [
      "Team",
      "Louise Deprez",
      "",
      "Guidance",
      "Tania Vandenbussche (UGent)",
      "",
      "Photography",
      <a
        href="https://studiodeprez.be/"
        target="_blank"
        rel="noopener noreferrer"
        className="a-text"
      >
        Studio Deprez
      </a>,
    ],
  },
];

export default projectArchive;
