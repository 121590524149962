// Signe/Trace
import frontSigneTrace from "./images/2020_BVFranseSchilderkunst/IMG_20200925_165427__01_S.jpg";
import ImSigneTrace1 from "./images/2020_BVFranseSchilderkunst/IMG_20200925_165741_M.jpg";
import ImSigneTrace2 from "./images/2020_BVFranseSchilderkunst/IMG_20200925_165750_M.jpg";
import ImSigneTrace3 from "./images/2020_BVFranseSchilderkunst/IMG_20200925_165754_M.jpg";
// Hennebique
import frontHennebique from "./images/2019_CapitaSelecta/6_knoop-plan-bovenste - Copy copy_S.jpg";
import ImHennebique1 from "./images/2019_CapitaSelecta/1_galerij-knoop-juist - Copy_M.jpg";
import ImHennebique2 from "./images/2019_CapitaSelecta/IMG_20210925_163459_M.jpg";
import ImHennebique3 from "./images/2019_CapitaSelecta/IMG_20210925_163845_M.jpg";
import ImHennebique4 from "./images/2019_CapitaSelecta/IMG_20210925_164004_M.jpg";

const exhibitionsArchive = [
  {
    type: "exhibition",
    title: "Hennebique and After.",
    titleEng: "Hennebique and After.",
    subtitle: "Beton in België 1890-2020",
    subtitleEng: "Concrete in Belgium 1890-2020",
    route: "/Exhibitions/Hennebique",
    location: "Gent, Paviljoen Charles Vandenhove",
    locationEng: "Ghent, Pavilion Charles Vandenhove",
    year: "2021",
    frontImage: frontHennebique,
    images: [ImHennebique2, ImHennebique1, ImHennebique3],
    omschrijving: [
      <a className="a-textBig">
        "In deze expositie tonen studenten ingenieur-architect van de
        Universiteit Gent het werk dat ze in de periode 2017-21 gemaakt hebben
        in het domein van de constructiegeschiedenis. In nauwe samenwerking met
        een team van lesgevers werden 75 constructies tot op het beton
        bestudeerd (tekst, digitaal model, 3D-print, Virtual Reality). De
        selectie brengt niet het volledige avontuur van het gewapend of
        ongewapend beton in België en bevat zowel bekende als anonieme,
        verdwenen als bewaard gebleven werken van uiteenlopend type,
        schaalniveau en experimenteel karakter."
        <br />
        <a
          href="https://www.ugent.be/vandenhove/nl/tentoonstellingen/archief/hennebique-and-after-beton-in-belgie-1890-2020"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBig"
        >
          - ugent.be/vandenhove
        </a>
        <br />
        <br />
        De virtuele tentoonstelling kan online bezocht worden op{" "}
        <a
          href="http://arch.ugent.be/hennebique"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBig"
        >
          http://arch.ugent.be/hennebique
        </a>
        .
      </a>,
    ],
    omschrijvingEng: [
      <a className="a-textBigEng">
        "The exhibition shows the results of an ongoing discovery in the field
        of construction history, done by UGent's master students in engineering
        architecture during the last 4 years. Their analyses (short essay,
        digital model, 3D print, VR presentation) of 75 constructions serve to
        outline the development of building in concrete in Belgium, from 1890
        onwards. Examples have been chosen from all parts of the country,
        displaying a variety of programmatic and constructional typologies. Well
        known classics go together with anonymous buildings - still existing or
        torn down. Churches, houses, world war shelters, museums, factories,
        mining constructions, university libraries, amusement halls, world's
        fair pavilions."
        <br />
        <a
          href="https://www.ugent.be/vandenhove/en/exhibitions/archive/hennebique-and-after-concrete-in-belgium-1890-2020"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBigEng"
        >
          - ugent.be/vandenhove
        </a>
        <br />
        <br />
        The virtual exhibition can be accessed online through{" "}
        <a
          href="http://arch.ugent.be/hennebique"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBigEng"
        >
          http://arch.ugent.be/hennebique
        </a>
        .
      </a>,
    ],
    opdrachtinfo: [
      "Curatoren",
      "Ronny De Meyer, Mil De Kooning (UGent)",
      "",
      "Scenografie",
      "Ronny De Meyer, Willem Bekers (UGent)",
      "",
      "i.s.m.",
      "Christof Deprest (printmaster), Wolf Dieleman (exhibition construction), Margo Romanus, Hanne Vandenhaute, Mohamed Moubile, Ruben Verstraeten (online exhibition), Brent Paelinck, Ben Pourveur, Laurens De Kooning (catalogue design), Tilke Devriese (editing), Jonathan Cuvelier (drone), Geertrui Ivens, Bram Vandeveire (production).",
      "",
      "Salle Royale de la Renommée",
      "Helinde Casteels, Louise Deprez (2019)",
      "Gust Mannaerts, Leander Meex, Julie Roelandt, Barbara Vandamme (2021)",
      "",
      "Fotografie",
      "Louise Deprez",
    ],
    opdrachtinfoEng: [
      "Exhibition curators",
      "Ronny De Meyer, Mil De Kooning (UGent)",
      "",
      "Exhibition lay-out",
      "Ronny De Meyer, Willem Bekers (UGent)",
      "",
      "i.c.w.",
      "Christof Deprest (printmaster), Wolf Dieleman (exhibition construction), Margo Romanus, Hanne Vandenhaute, Mohamed Moubile, Ruben Verstraeten (online exhibition), Brent Paelinck, Ben Pourveur, Laurens De Kooning (catalogue design), Tilke Devriese (editing), Jonathan Cuvelier (drone), Geertrui Ivens, Bram Vandeveire (production).",
      "",
      "Salle Royale de la Renommée",
      "Helinde Casteels, Louise Deprez (2019)",
      "Gust Mannaerts, Leander Meex, Julie Roelandt, Barbara Vandamme (2021)",
      "",
      "Photography",
      "Louise Deprez",
    ],
  },
  {
    type: "exhibition",
    title: "Signe/Trace",
    subtitle: "Schilders uit de collectie Vandenhove",
    subtitleEng: "Painters from the VANDENHOVE Collection",
    route: "/Exhibitions/SigneTrace",
    location: "Gent, Paviljoen Charles Vandenhove",
    locationEng: "Ghent, Pavilion Charles Vandenhove",
    year: "2020",
    frontImage: frontSigneTrace,
    images: [ImSigneTrace1, ImSigneTrace3, ImSigneTrace2],
    omschrijving: [
      <a className="a-textBig">
        "De kunstcollectie VANDENHOVE bestaat voornamelijk uit Europese en
        Franse schilderkunst uit de periode 1960 tot 2000. Uit de collectie zijn
        een twintigtal werken gekozen die een beeld geven van het brede spectrum
        aan picturale benaderingen, gaande van het vinden van geheimtekens tot
        het maken van sporen, en van het schrijven en signeren tot het boetseren
        van de verfmassa. Met werken van Rogier Bissière, Olivier Debré, Luis
        Feito, Loïc Le Groumellec, Simon Hantaï, Alfred Manessier, André
        Marfaing, Henri Michaux, Gérard Schneider en Pierre Soulages."
        <br />
        <a
          href="https://www.ugent.be/vandenhove/nl/tentoonstellingen/archief/signe-trace-schilders-uit-de-collectie-vandenhove"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBig"
        >
          - ugent.be/vandenhove
        </a>
      </a>,
    ],
    omschrijvingEng: [
      <a className="a-textBigEng">
        "The art collection VANDENHOVE consists mainly of European and French
        paintings from the period 1960 to 2000. Some twenty works have been
        selected from the collection that reflect the broad spectrum of
        pictorial approaches, ranging from finding memory marks to making
        traces, and from writing and signing to modelling the mass of paint.
        With works by Rogier Bissière, Olivier Debré, Luis Feito, Loïc Le
        Groumellec, Simon Hantaï, Alfred Manessier, André Marfaing, Henri
        Michaux, Gérard Schneider and Pierre Soulages."
        <br />
        <a
          href="https://www.ugent.be/vandenhove/en/exhibitions/archive/sign-trace-painters-from-the-vandenhove-collection"
          target="_blank"
          rel="noopener noreferrer"
          className="a-textBigEng"
        >
          - ugent.be/vandenhove
        </a>
      </a>,
    ],
    opdrachtinfo: [
      "Curator",
      "prof. Bart Verschaffel (UGent)",
      " ",
      "i.s.m.",
      "Helinde Casteels, Carole de Buck Van Overstraeten, Louise Deprez, Seppe Feijen, Gust Hamelinck, Emma Leten, Jannes Vandercammen, Anton Vanneuville, Wout Vermijs",
      " ",
      "Fotografie",
      "Louise Deprez",
    ],
    opdrachtinfoEng: [
      "Exhibition curator",
      "prof. Bart Verschaffel (UGent)",
      " ",
      "i.c.w.",
      "Helinde Casteels, Carole de Buck Van Overstraeten, Louise Deprez, Seppe Feijen, Gust Hamelinck, Emma Leten, Jannes Vandercammen, Anton Vanneuville, Wout Vermijs",
      " ",
      "Photography",
      "Louise Deprez",
    ],
  },
];

export default exhibitionsArchive;
