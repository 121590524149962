import React from "react";
import "./pages.css";
//import "./timeline.css";
import Navbar from "../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import portrait from "./images/portrait.jpg";

const About = () => {
  return window.innerWidth < 768 ? (
    <div>
      <div className="item">
        <Navbar page="About" />
      </div>
      <div className="item justify-content-right">
        <div>
        <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">Ir. Architect</a>
              <br />
              <a className="a-textTimeline">
                <a
                  href="https://margearchitecten.be/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="a-text"
                >
                  Marge Architecten
                </a>
              </a>
              <br />
              <a className="a-textTimeline">2023 - Present</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">Academic assistant staff</a>
              <br />
              <a className="a-textTimeline">
                KU Leuven: Faculty of Engineering Technology
              </a>
              <br />
              <a className="a-textTimeline">2023 - Present</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">Researcher</a>
              <br />
              <a className="a-textTimeline">
                Ghent University: Faculty of Engineering and Architecture
              </a>
              <br />
              <a className="a-textTimeline">2020 - 2023</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimelineSmall">Welding course</a>
              <br />
              <a className="a-textTimeline">2021 - 2022</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircleSmall" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">
                Master of Engineering: Architectural Design and Construction
                Techniques
              </a>
              <br />
              <a className="a-textTimeline">Ghent University</a>
              <br />
              <a className="a-textTimeline">2018 - 2020</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">
                Bachelor of Engineering: Architecture
              </a>
              <br />
              <a className="a-textTimeline">Ghent University</a>
              <br />
              <a className="a-textTimeline">2015 - 2018</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
        </div>

        <div className="item justify-content-right">
          <div>
            <div>
              <div className="item">
                <a className="a-title">Computer</a>
                <br />
                <a className="a-text">
                  CAD: AutoCAD ● ● ●, Rhino & Grasshopper ● ● ●, Revit ●
                </a>
                <br />
                <a className="a-text">
                  Programming: Python ● ●, C# ● ●, web development (React) ●
                </a>
                <br />
                <a className="a-text">
                  Adobe: Illustrator ● ●, Photoshop ●, InDesign ●
                </a>
                <br />
                <a className="a-text">
                  Microsoft Office: Word ● ●, Powerpoint ● ●, Excel ●
                </a>
                <br />
                <br />
                <a className="a-title">Languages</a>
                <br />
                <a className="a-text">Dutch ● ● ●</a>
                <br />
                <a className="a-text">English ● ●</a>
                <br />
                <a className="a-text">French & German ●</a>
                <br />
                <br />
                <a className="a-title">Leisure</a>
                <br />
                <a className="a-text">Backpacking</a>
                <br />
                <a className="a-text">Welding, sewing</a>
                <br />
                <a className="a-text">Acrobatic gymnastics, trainer</a>
              </div>
              <div className="item">
                <img src={portrait} width="90%"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="containerProject">
      <div className="item">
        <Navbar page="About" />
      </div>
      <div className="item justify-content-right">
        <div>
        <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">Ir. Architect</a>
              <br />
              <a className="a-textTimeline">
                <a
                  href="https://margearchitecten.be/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="a-text"
                >
                  Marge Architecten
                </a>
              </a>
              <br />
              <a className="a-textTimeline">2023 - Present</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">Academic assistant staff</a>
              <br />
              <a className="a-textTimeline">
                KU Leuven: Faculty of Engineering Technology
              </a>
              <br />
              <a className="a-textTimeline">2023 - Present</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">Researcher</a>
              <br />
              <a className="a-textTimeline">
                Ghent University: Faculty of Engineering and Architecture
              </a>
              <br />
              <a className="a-textTimeline">2020 - 2023</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimelineSmall">Welding course</a>
              <br />
              <a className="a-textTimeline">2021 - 2022</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircleSmall" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">
                Master of Engineering: Architectural Design and Construction
                Techniques
              </a>
              <br />
              <a className="a-textTimeline">Ghent University</a>
              <br />
              <a className="a-textTimeline">2018 - 2020</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
          <div className="containerTimeline">
            <div className="itemTimeline">
              <a className="a-titleTimeline">
                Bachelor of Engineering: Architecture
              </a>
              <br />
              <a className="a-textTimeline">Ghent University</a>
              <br />
              <a className="a-textTimeline">2015 - 2018</a>
            </div>
            <div className="div-timelineLine">
              <div className="div-timelineCircle" />
            </div>
          </div>
        </div>

        <div className="item justify-content-right">
          <div>
            <div className="container6040">
              <div className="item">
                <a className="a-title">Computer</a>
                <br />
                <a className="a-text">
                  CAD: AutoCAD ● ● ●, Rhino & Grasshopper ● ● ●, Revit ●
                </a>
                <br />
                <a className="a-text">
                  Programming: Python ● ●, C# ● ●, web development (React) ●
                </a>
                <br />
                <a className="a-text">
                  Adobe: Illustrator ● ●, Photoshop ●, InDesign ●
                </a>
                <br />
                <a className="a-text">
                  Microsoft Office: Word ● ●, Powerpoint ● ●, Excel ●
                </a>
                <br />
                <br />
                <a className="a-title">Languages</a>
                <br />
                <a className="a-text">Dutch ● ● ●</a>
                <br />
                <a className="a-text">English ● ●</a>
                <br />
                <a className="a-text">French & German ●</a>
                <br />
                <br />
                <a className="a-title">Leisure</a>
                <br />
                <a className="a-text">Backpacking</a>
                <br />
                <a className="a-text">Welding, sewing</a>
                <br />
                <a className="a-text">Acrobatic gymnastics, trainer</a>
              </div>
              <div className="item">
                <img src={portrait} width="90%"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
