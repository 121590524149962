import React from "react";
//import Thumbnail from '../components/Navbar/Thumbnail.js'; // Import the Thumbnail component
import projectsArchive from "./projectsArchive";
import { Link } from "react-router-dom";
import "./pages.css";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../components/Navbar/Navbar";

const Projects = (props) => {
  return window.innerWidth < 768 ? (
    <div>
      <div className="item">
        <Navbar page="Projects" subtype={props.subtype} />
      </div>
      <div>
        <div className="item justify-content-right">
          <div>
            {projectsArchive.map((item, index) => {
              return (props.subtype === item.subtype) |
                (props.subtype === "") ? (
                <div>
                  <div className="item">
                    <Link to={item.route} className="a-link float-right">
                      <img src={item.frontImage} width="100%"></img>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={item.route} className="a-link">
                      <a className="a-title">{item.title}</a>
                      <br />
                      <a className="a-text">{item.location}</a>
                      <br />
                      <a className="a-text">{item.year}</a>
                    </Link>
                  </div>
                </div>
              ) : (
                <div></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="container3070">
      <div className="item">
        <Navbar page="Projects" subtype={props.subtype} />
      </div>
      <div className="container3070">
        <div></div>
        <div className="item justify-content-right">
          <div>
            {projectsArchive.map((item, index) => {
              return (props.subtype === item.subtype) |
                (props.subtype === "") ? (
                <div className="container5050">
                  <div className="item">
                    <Link to={item.route} className="a-link">
                      <a className="a-title">{item.title}</a>
                      <br />
                      <a className="a-text">{item.location}</a>
                      <br />
                      <a className="a-text">{item.year}</a>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={item.route} className="a-link float-right">
                      <img src={item.frontImage} width="100%"></img>
                    </Link>
                  </div>
                </div>
              ) : (
                <div></div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
