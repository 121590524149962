import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const NavbarProjectsBack = () => {
  return (
    <div className="sidenav">
      <div>
        <Link to="/About" className="a-dark">
          Louise Deprez
        </Link><Link to="/About" className="box-shadow-menu"></Link>
        <br />
        <br />
      </div>
      <div>
        <Link to="/Projects" className="a-light">
          Back
        </Link>
      </div>
    </div>
  );
};
export default NavbarProjectsBack;
