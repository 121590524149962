import React from "react";
import "./pages.css";
import Navbar from "../components/Navbar/Navbar";
import researchArchive from "./researchArchive";
import { Link } from "react-router-dom";
import "./pages.css";

const Research = () => {
  return window.innerWidth < 768 ? (
    <div>
      <div className="item">
        <Navbar page="Research" />
      </div>
      <div>
        <div className="item justify-content-right">
          <div>
            {researchArchive.map((item, index) => {
              return (
                <div>
                  <div className="item">
                    <Link to={item.route} className="a-link float-right">
                      <img src={item.frontImage} width="100%"></img>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={item.route} className="a-link">
                      <a className="a-title">{item.title}</a>
                      <br />
                      <a className="a-text">{item.location}</a>
                      <br />
                      <a className="a-text">{item.year}</a>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="container3070">
      <div className="item">
        <Navbar page="Research" />
      </div>
      <div className="container3070">
        <div></div>
        <div className="item justify-content-right">
          <div>
            {researchArchive.map((item, index) => {
              return (
                <div className="container5050">
                  <div className="item">
                    <Link to={item.route} className="a-link">
                      <a className="a-title">{item.title}</a>
                      <br />
                      <a className="a-text">{item.location}</a>
                      <br />
                      <a className="a-text">{item.year}</a>
                    </Link>
                  </div>
                  <div className="item">
                    <Link to={item.route} className="a-link float-right">
                      <img src={item.frontImage} width="100%"></img>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Research;
