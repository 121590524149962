import React from "react";
import "./pages.css";
//import "./timeline.css";
import Navbar from "../components/Navbar/Navbar";
import projectsArchive from "./projectsArchive";
import SimpleImageSlider from "react-simple-image-slider";
import { Link } from "react-router-dom";

import startbutton from "./images/ButtonLines_S.png";

import palazzo from "./images/gallery/_MG_0930_M.jpg";
import rectoraat from "./images/gallery/_MG_1058_2_crop_M.jpg";
import winkelpui from "./images/gallery/_MG_1108_M.jpg";
import tweelinggebouw from "./images/gallery/_MG_1251_M.jpg";
import capitaselecta from "./images/gallery/IMG_20210925_163510_crop_BW.jpg";
import strettorek from "./images/gallery/_MG_9644.jpg";
import bvfrschk from "./images/gallery/IMG_20200925_165702_crop.jpg";

const images = [
  { url: palazzo },
  { url: rectoraat },
  { url: tweelinggebouw },
  { url: capitaselecta },
  { url: winkelpui },
  { url: bvfrschk },
];

const Photogallery = () => {
  return (
    <div>
      <div
        className="div-start"
        style={{
          position: "absolute",
          zIndex: 1,
        }}
      >
        <Link to="/About" className="a-darkStart">
          Louise Deprez
        </Link>
        <Link to="/About" className="box-shadow-menu"></Link>
      </div>
      <div style={{ zIndex: 100 }}>
        <SimpleImageSlider
          width="100%"
          height="100%"
          images={images}
          showBullets={false}
          showNavs={true}
          onClick
        />
      </div>
    </div>
  );
};

export default Photogallery;
