import React from "react";
import "./pages.css";
import NavbarProjectsBack from "../components/Navbar/NavbarProjectsBack";
import NavbarExhibitionsBack from "../components/Navbar/NavbarExhibitionsBack";
import NavbarResearchBack from "../components/Navbar/NavbarResearchBack";

const ProjectPage = (props) => {
  return window.innerWidth < 768 ? (
    <div>
      {props.project.type === "project" ? (
        <div className="item">
          <NavbarProjectsBack />
        </div>
      ) : props.project.type === "exhibition" ? (
        <div className="item">
          <NavbarExhibitionsBack />
        </div>
      ) : props.project.type === "research" ? (
        <div className="item">
          <NavbarResearchBack />
        </div>
      ) : (
        <div></div>
      )}
      <div className="item justify-content-right">
        <div className="item">
          <img src={props.project.images[0]} width="100%"></img>
        </div>
        <div className="item">
          <a className="a-title">{props.project.title}</a>
          <br></br>
          <a className="a-text">{props.project.subtitle}</a>
          <br></br>
          <br></br>
          {props.project.omschrijving.map((item, index) => {
            return item;
          })}
          <br></br>
          <br></br>
          <div className="div-leftborder">
            <div className="div-eng">
              {props.project.omschrijvingEng.map((item, index) => {
                return item;
              })}
            </div>
          </div>
          <br></br>
          <br></br>
          {props.project.opdrachtinfoEng.map((item, index) => {
            return (
              <div>
                <a className="a-text">{item}</a>
                <br></br>
              </div>
            );
          })}
        </div>
        <div className="item">
          {props.project.images.slice(1).map((item, index) => {
            return <img src={item} width="100%"></img>;
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className="containerProject">
      {props.project.type === "project" ? (
        <div className="item">
          <NavbarProjectsBack />
        </div>
      ) : props.project.type === "exhibition" ? (
        <div className="item">
          <NavbarExhibitionsBack />
        </div>
      ) : props.project.type === "research" ? (
        <div className="item">
          <NavbarResearchBack />
        </div>
      ) : (
        <div></div>
      )}
      <div className="item justify-content-right">
        <div className="container5050">
          <div className="item">
            {props.project.images.map((item, index) => {
              return <img src={item} width="100%"></img>;
            })}
          </div>
          <div className="item">
            <a className="a-title">{props.project.title}</a>
            <br></br>
            <a className="a-text">{props.project.subtitle}</a>
            <br></br>
            <br></br>
            {props.project.omschrijving.map((item, index) => {
              return item;
            })}
            <br></br>
            <br></br>
            <div className="div-leftborder">
              <div className="div-eng">
                {props.project.omschrijvingEng.map((item, index) => {
                  return item;
                })}
              </div>
            </div>
            <br></br>
            <br></br>
            {props.project.opdrachtinfoEng.map((item, index) => {
              return (
                <div>
                  <a className="a-text">{item}</a>
                  <br></br>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
