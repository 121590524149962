import React from "react";
import "./App.css";
//import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import Exhibitions from "./pages/Exhibitions";
import Research from "./pages/Research";
import About from "./pages/About";
import Projects from "./pages/Projects";
import ProjectPage from "./pages/ProjectPage";
import projectsArchive from "./pages/projectsArchive";
import exhibitionsArchive from "./pages/exhibitionsArchive";
import researchArchive from "./pages/researchArchive";
import Photogallery from "./pages/Photogallery";

// Replace SVG file with your logo
import logo from "./logo.svg";

import "./App.css";
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<Photogallery />} />
        <Route path="#/" exact element={<Photogallery />} />
        <Route path="/#" exact element={<Photogallery />} />
        <Route path="/About" element={<About />} />
        <Route
          path="/Architecture"
          element={<Projects subtype={"architecture"} />}
        />
        <Route path="/Objects" element={<Projects subtype={"object"} />} />
        <Route path="/Projects" element={<Projects subtype={""} />} />
        <Route path="/Exhibitions" element={<Exhibitions />} />
        <Route path="/Research" element={<Research />} />
        {projectsArchive.map((item, index) => {
          return (
            <Route path={item.route} element={<ProjectPage project={item} />} />
          );
        })}
        {exhibitionsArchive.map((item, index) => {
          return (
            <Route path={item.route} element={<ProjectPage project={item} />} />
          );
        })}
        {researchArchive.map((item, index) => {
          return (
            <Route path={item.route} element={<ProjectPage project={item} />} />
          );
        })}
      </Routes>
    </HashRouter>
  );
}

export default App;
